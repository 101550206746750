.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5; /* Match the light background of the other components */
}

.login-form {
    width: 300px;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff; /* Consistent with the form background in other components */
    color: #333; /* Text color matching other components */
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Lighter shadow for a subtle effect */
    font-family: 'Arial', sans-serif; /* Ensures font consistency */
}

.login-form img {
    width: 150px; /* Adjusted size to be consistent with logo styling */
    margin-bottom: 20px;
}

.login-form h2 {
    margin-bottom: 20px;
    color: #333; /* Match the heading color with the project and dashboard components */
    font-size: 1.5em; /* Ensure font size consistency */
    font-family: 'Arial', sans-serif; /* Ensures font consistency */
}

.form-group {
    margin-bottom: 15px;
    text-align: left;
}

.form-group label {
    display: block;
    margin-bottom: 5px;
    color: #333; /* Updated label color to match the text color of other components */
    font-family: 'Arial', sans-serif; /* Ensures font consistency */
}

.form-group input {
    width: 100%;
    padding: 10px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff; /* Light background to match the input fields in other components */
    color: #333; /* Text color consistent with other input fields */
    font-family: 'Arial', sans-serif; /* Ensures font consistency */
}

button {
    width: 100%;
    padding: 10px;
    background-color: #333; /* Dark button color matching the save/back buttons in other components */
    color: white;
    border: none;
    border-radius: 20px; /* Rounded corners consistent with buttons in other components */
    cursor: pointer;
    transition: background-color 0.3s;
    font-family: 'Arial', sans-serif; /* Ensures font consistency */
}

button:hover {
    background-color: #555; /* Slightly lighter hover effect for consistency */
}
