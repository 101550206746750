body {
    background-color: #f5f5f5;
    color: #333;
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
}

.dashboard-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    min-height: 100vh;
}



.logout-button:hover {
    background-color: #555;
}


.project-details {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1200px;
}



.back-button:hover {
    background-color: #555;
}

.projects-list-container {
    text-align: center;
}

.projects-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    width: 100%;
    max-width: 800px;
}

.project-item {
    margin: 10px 0;
}

.project-button {
    background-color: #333;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 1.2em;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s;
    width: 100%;
}

.project-button:hover {
    background-color: #555;
}

.no-projects {
    font-size: 2em;
}

.user-message {
    background-color: #555;
    color: #fff;
    padding: 10px 20px;
    border-radius: 5px;
    text-align: center;
    font-size: 1em;
    margin-top: 20px;
    width: 100%;
    max-width: 1200px;
    transition: background-color 0.3s;
}


/*------------------*/

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1200px;
    margin-bottom: 20px;
    position: relative;
}

.logo {
    width: 150px;
    margin: 20px auto;
}

.logout-button {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #fff;
    height: 40px;
    width: 125px;
    background-color: #333;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 1em;
    cursor: pointer;
    text-align: center;
    transform: translateY(0); /* Aligns the button vertically */
}

.back-button {
    position: absolute;
    top: 20px;
    left: 20px;
    color: #fff;
    height: 40px;
    width: 125px;
    background-color: #333;
    padding: 10px 20px;
    border-radius: 20px;
    font-size: 1em;
    cursor: pointer;
    text-align: center;
    transform: translateY(0); /* Aligns the button vertically */
}

.icon-logout {
    width: 1.5em; /* Adjust size as needed */
    height: 1.5em; /* Ensure it's proportional */
    margin-left: 0.5em; /* Space between icon and text */
    margin-bottom: 0.2em;
    vertical-align: middle;
}

.icon-back {
    width: 1.5em; /* Adjust size as needed */
    height: 1.5em; /* Ensure it's proportional */
    margin-right: 0.5em; /* Space between icon and text */
    margin-bottom: 0.2em;
    vertical-align: middle;
}
