.confirm-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.confirm-popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.confirm-popup-content p {
    margin-bottom: 16px;
}

.confirm-popup-content button {
    margin: 2% 0 2% 0;
    width: 100%;
    height: 10%;
    cursor: pointer;
}
