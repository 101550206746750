.project-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
}

.project-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%;
}

.project-title {
    font-size: 2em;
    margin: 0;
    color: #333;
}

.project-description {
    font-size: 1.2em;
    color: #777;
    margin-bottom: 20px;
}

.table-container {
    overflow-x: auto;
    margin-bottom: 20px;
    max-width: 100%; /* Keep container's width responsive */
    max-height: calc(62vh - 40px); /* 70% of the viewport height, minus padding or margins */
    overflow-y: auto; /* Allow vertical scrolling */
    position: relative; /* Keep scroll confined within this container */
}



.project-table {
    border-collapse: collapse;
    table-layout: auto; /* Ensure columns adjust based on content */
    width: 100%; /* Optional: You can keep this for responsiveness */
}

.project-table-cell {
    padding: 10px;
    text-align: left;
    color: #333;
    background-color: #fff;
    min-width: 150px; /* Set a minimum width for cells */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis (...) for overflowing text */
}

/* Sticky column styling */
.project-table-cell.sticky-column {
    position: sticky; /* Makes it sticky */
    left: 0; /* Fixes it to the left of the table */
    z-index: 2; /* Ensures it stays above other content */
    background-color: #f9f9f9; /* Sticky column background color for contrast */
    border-right: 1px solid #ddd; /* Optional: Visual separation */
}

.project-table-header {
    background-color: #f9f9f9;
    padding: 10px;
    text-align: left;
    font-weight: bold;
    color: #333;
    white-space: nowrap; /* Prevent text wrapping in headers */
    position: sticky; /* Make the header sticky */
    top: 0; /* Stick the header to the top of the table container */
    z-index: 1; /* Ensure header stays above other table content */
}

.project-table-header.sticky-column {
    position: sticky; /* Sticky for the header */
    left: 0; /* Aligns with the data cells */
    z-index: 3; /* Ensures it stays above the body rows */
    background-color: #eee; /* Slightly different header background */
    border-right: 1px solid #ddd; /* Optional: Visual separation */
}


.project-table th,
.project-table td {
    border: 1px solid #ddd;
}

.project-table select,
.project-table input {
    width: 100%;
    padding: 8px;
    box-sizing: border-box;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.save-button-container {
    text-align: center;
}

.submit-button {
    background-color: #333;
    color: #fff;
    padding: 15px 30px;
    border: none;
    border-radius: 30px;
    font-size: 1em;
    cursor: pointer;
    width: 340px;
    align-self: center;
    transition: background-color 0.3s ease;
}

.submit-button:hover {
    background-color: #555;
}

.no-units-message,
.error-message {
    font-size: 1.2em;
    color: #333;
}

.non-editable-input {
    padding: 8px; /* Same as project-table input padding */
    border: 1px solid #ccc; /* Same as project-table input border */
    border-radius: 4px; /* Same as project-table input border-radius */
    background-color: #f9f9f9; /* Slightly lighter background to indicate it's non-editable */
    color: #333; /* Consistent with text color in project-table-cell */
    min-width: 150px; /* Same as project-table-cell min-width */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflow */
    text-overflow: ellipsis; /* Show ellipsis for overflowing text */
    display: inline-block; /* Make it look like an input */
    text-align: center; /* Center the text */
}
